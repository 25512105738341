var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"RMaFMomqC1qv2ExUhDOW8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://80f0e6793afb4b818e9fad6970e2d4de@o317257.ingest.sentry.io/6219427',
  environment: process.env.NEXT_PUBLIC_APP_ENV,
});
